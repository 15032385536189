<template>
	<div class="operations-visit-detail">
		<v-layout class="pt-3 pl-3 pr-3">
			<v-flex xs12>
				<v-layout align-center fill-height>
					<!--
					<v-flex xs1>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn icon ripple v-on="on" @click.stop="showVesselHistoryDialog = true">
									<v-icon>far fa-history</v-icon>
								</v-btn>
							</template>
							<span>{{ $t('operationsvisitdetail.visits.history') }}</span>
						</v-tooltip>
					</v-flex>
					-->
					<v-flex xs7>
						<span class="operations-visit-detail__title">{{ vesselname }}</span>
					</v-flex>
					<v-flex xs7>
						<span class="operations-visit-detail__portcall">{{ this.visit.portcallnumber }}</span>
					</v-flex>
					<v-flex xs2 class="operations-visit-detail__status" v-if="this.visit.statusname && this.visit.statusname !== status">
						<div class="text-center">
							<span style="color: #cb2b16">{{ this.visit.statusname }}</span>
						</div>
					</v-flex>
					<v-flex xs2 class="operations-visit-detail__status">
						<div class="text-center">
							<span :class="statusClass">{{ $t(`visit.statusid.${this.visit.statusid.toLowerCase()}.title`).toUpperCase() }}</span>
						</div>
					</v-flex>
					<v-flex xs1>
						<operations-visit-detail-notifications
							v-if="visit.statusid !== 'FI' && $store.getters.hasFunctionality('CREATE_SUBSCRIPTION')"
							:visit="visit"
						></operations-visit-detail-notifications>
					</v-flex>
					<v-flex xs1>
						<v-btn icon ripple @click="closeDialog()">
							<v-icon>far fa-times</v-icon>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
		<v-layout class="pl-3 pr-3" ref="dynamicTable">
			<v-flex xs12>
				<v-container fluid class="resume">
					<table>
						<tr class="resume_title">
							<th>{{ $t('stop.num') }}</th>
							<th>{{ $t('stop.type') }}</th>
							<th>{{ $t('stop.location') }}</th>
							<th>{{ $t('stop.state') }}</th>
							<th>{{ $t('stop.performance') }}</th>
						</tr>
						<tr
							class="resume_subtitle"
							:class="{ selected: stop.id === stopSelected.id }"
							v-for="(stop, index) in stops"
							:key="index"
							@click="stopSelected = stop"
						>
							<td>{{ stop.num }}</td>
							<td>{{ getType(stop) }}</td>
							<td>{{ getLocation(stop) }}</td>
							<td>{{ getStatus(stop) }}</td>
							<td>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<span v-on="on" class="performance" :class="stop.performanceClass">{{ stop.performanceLabel }}</span>
									</template>
									<span>{{ stop.performanceTooltip }}</span>
								</v-tooltip>
							</td>
						</tr>
					</table>
				</v-container>
			</v-flex>
		</v-layout>
		<v-layout v-if="stopSelected">
			<v-flex xs12>
				<v-tabs slider-color="#00A9D8">
					<!--<v-tab>{{ $t('operationsvisitdetail.tabs.overview') }}</v-tab>-->
					<v-tab>{{ $t('operationsvisitdetail.tabs.timeline') }}</v-tab>
					<!--<v-tab>{{ $t('operationsvisitdetail.tabs.logs') }}</v-tab>-->
					<v-tab>{{ $t('operationsvisitdetail.tabs.performance') }}</v-tab>
					<v-tab>{{ $t('operationsvisitdetail.tabs.chat') }}</v-tab>
					<!--
					<v-tab-item>
						<v-divider />
						<operations-visit-detail-overview-bar-chart
							v-if="stopSelected.atd !== null && stopSelected.status === 'FINALIZED'"
							:visit="visit"
							:stopSelected="stopSelected"
						></operations-visit-detail-overview-bar-chart>
						<operations-visit-detail-stop-not-finalized
							v-else
							:visit="visit"
							:stopSelected="stopSelected"
						></operations-visit-detail-stop-not-finalized>
					</v-tab-item>
					-->
					<v-tab-item>
						<v-divider />
						<operations-visit-detail-timeline :visit="visit"></operations-visit-detail-timeline>
					</v-tab-item>
					<!--<v-tab-item>
						<v-divider />
						<operations-visit-detail-logs :visit="visit" :stopSelected="stopSelected"></operations-visit-detail-logs>
					</v-tab-item>-->
					<v-tab-item>
						<v-divider />
						<operations-visit-detail-overview-bar-chart
							v-if="stopSelected.atd !== null && stopSelected.status === 'FINALIZED'"
							:visit="visit"
							:stopSelected="stopSelected"
						/>
						<operations-visit-detail-stop-not-finalized v-else :visit="visit" :stopSelected="stopSelected" />
						<operations-visit-detail-performance :visit="visit" :stopSelected="stopSelected" />
					</v-tab-item>
					<v-tab-item>
						<v-divider />
						<operations-chat :visit="visit" />
					</v-tab-item>
				</v-tabs>
			</v-flex>
		</v-layout>
		<v-dialog v-model="showVesselHistoryDialog" max-width="1200">
			<operations-visit-detail-history :visit="visit" v-on:close-dialog="showVesselHistoryDialog = false"></operations-visit-detail-history>
		</v-dialog>
	</div>
</template>

<script>
import DifferenceMixin from '@/mixins/HHmmDifferenceMixin';
import OperationsVisitDetailNotifications from './OperationsVisitDetailNotifications.vue';
import OperationsVisitDetailOverviewBarChart from './overview/OperationsVisitDetailOverviewBarChart.vue';
import OperationsVisitDetailTimeline from './timeline/OperationsVisitDetailTimeline.vue';
import OperationsVisitDetailLogs from './logs/OperationsVisitDetailLogs.vue';
import OperationsVisitDetailPerformance from './performance/OperationsVisitDetailPerformance.vue';
import OperationsVisitDetailStopNotFinalized from './OperationsVisitDetailStopNotFinalized.vue';
import OperationsVisitDetailHistory from './history/OperationsVisitDetailHistory.vue';
import OperationsChat from '@/components/chat/OperationsChat';

export default {
	mixins: [DifferenceMixin],
	components: {
		OperationsVisitDetailNotifications,
		OperationsVisitDetailOverviewBarChart,
		OperationsVisitDetailTimeline,
		OperationsVisitDetailLogs,
		OperationsVisitDetailPerformance,
		OperationsVisitDetailStopNotFinalized,
		OperationsVisitDetailHistory,
		OperationsChat
	},
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			stopSelected: {},
			showVesselHistoryDialog: false
		};
	},
	computed: {
		stops() {
			const stops = [];
			for (let i = 0, stopsLength = this.visit.stops.length; i < stopsLength; i++) {
				const stop = Object.assign({}, this.visit.stops[i]);
				const description = stop.location !== null ? stop.location : '';
				stop.description = `${stop.num} - ${description}`;
				this.setPerformance(stop);
				stops.push(stop);
			}
			return stops;
		},
		vesselname() {
			console.log('vesselname - this-visit', JSON.stringify(this.visit, null, 5));
			return this.visit.vesselname;
		},
		statusClass() {
			return this.visit.statusid.toLowerCase();
		},
		status() {
			return this.$t(`visit.status.${this.visit.status.toLowerCase()}`);
		}
	},
	mounted() {
		if (this.stops && this.stops.length > 0) {
			this.stopSelected = this.stops[0];
		}

		const dynamicTableHeight = this.$refs.dynamicTable.clientHeight;
		/*
			50 -> Height Vessel Name
			dynamicTableHeight -> Height tabla Stops
			50 -> Height Tabs
			110 -> Height Search and Filter timeline
		*/
		const visitDetailHeight = 50 + dynamicTableHeight + 50 + 110;
		document.documentElement.style.setProperty('--operations-visit-detail-height', `${visitDetailHeight}px`);
	},
	methods: {
		getType(stop) {
			return !stop.isanchorage ? this.$t('stop.berth') : this.$t('stop.anchored');
		},
		getLocation(stop) {
			return stop.location !== null ? stop.location : '';
		},
		getStatus(stop) {
			return stop.status !== null ? this.$t(`stop.status.${stop.statusid.toLowerCase()}.title`) : '';
		},
		setPerformance(stop) {
			// Port Stay (PH) = POF – APS
			stop.portStay = this.getDifference(this.visit.aps, this.visit.pof);
			console.log('Port Stay (PH)', stop.portStay);
			// Berth Stay (BH) = RTS – FLA
			stop.berthStay = this.getDifference(stop.fla, stop.rts);
			console.log('Berth Stay (BH)', stop.berthStay);

			if (stop.portStay && stop.berthStay) {
				// Performance = PH – BH
				const [portStayHours, portStayMinutes] = stop.portStay.split(':');
				const [berthStayHours, berthStayMinutes] = stop.berthStay.split(':');
				const portStayTotalMinutes = parseInt(portStayHours) * 60 + parseInt(portStayMinutes);
				const berthStayTotalMinutes = parseInt(berthStayHours) * 60 + parseInt(berthStayMinutes);
				let performance = 100 - (berthStayTotalMinutes * 100) / portStayTotalMinutes;
				performance = Math.round(performance * 100) / 100; // round 2 decimals
				stop.performance = performance;
				stop.performanceLabel = performance < 5 ? `+${performance}%` : `-${performance} %`;
				stop.performanceClass = performance < 5 ? 'performance_positive' : 'performance_negative';
				const performanceTooltipParams = {
					portStay: this.formatDifference(stop.portStay),
					berthStay: this.formatDifference(stop.berthStay)
				};
				stop.performanceTooltip = this.$t('operationsvisitdetailoverview.performance.tooltip', performanceTooltipParams);
				console.log('Performance', stop.performance, stop.performanceTooltip);
			}
		},
		closeDialog() {
			this.$puiEvents.$emit('operations-navigationDrawer-closed');
		}
	}
};
</script>

<style lang="postcss">
.operations-visit-detail {
	&__title {
		font-size: 24px;
		color: #1a2a4b;
	}
	&__portcall {
		font-size: 20px;
		color: #1a2a4b;
	}
	& .v-tabs__div {
		font-size: 15px;
		color: #1a2a4b;
	}
	&__status {
		background-color: rgba(246, 111, 14, 0.1);
		border-radius: 2px;
		font-weight: 500;
		font-size: 15px;
		text-transform: capitalize;
		margin-left: 2px;
		margin-right: 2px;
		& .in {
			color: #f66f0e;
		}
		& .an {
			color: #cb2b16;
		}
		& .fi {
			color: #f66f0e;
		}
	}
	& table {
		width: 100%;
	}
	& td {
		text-align: center;
		padding: 2px 0;
	}
	& .resume {
		background-color: #f5f7f7;
		border-radius: 8px;
		font-size: 14px;
		&_title {
			font-weight: 500;
			font-size: 14px;
			color: #303e5a;
		}
		&_subtitle {
			font-weight: normal;
			font-size: 14px;
			color: #464f63;
			cursor: pointer;
		}
	}
	& .selected {
		background-color: rgba(246, 111, 14, 0.1);
	}
	& .performance {
		font-weight: bold;
		font-size: 12px;
		color: #ffffff;
		padding: 2px;
		border-radius: 2px;
		&_positive {
			background-color: #61ad4e;
		}
		&_negative {
			background-color: #cb2b16;
		}
	}
	& .v-text-field--outline {
		& .v-select__selections {
			padding-top: 0px !important;
		}
		& .v-input__append-inner {
			margin-top: 8px !important;
		}
	}
	&-table {
		width: 100%;

		& tr:nth-child(even) {
			background-color: #f5f7f7;
		}
		& td {
			text-align: center;
			padding: 8px 0;
		}
		&__header {
			font-weight: bold;
			font-size: 13px;
			color: #6f7480;
		}
		&__content {
			font-weight: normal;
			font-size: 14px;
			color: #464f63;
		}
	}
}
</style>
