<template>
	<v-layout align-center wrap class="operations-toolbar">
		<div>
			<v-btn text small @click.native="decrementDate">
				<v-icon>far fa-chevron-left</v-icon>
			</v-btn>
		</div>
		<v-flex xs10 md2 lg1>
			<v-menu
				v-model="menuDate"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				max-width="290px"
				min-width="290px"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						v-model="computedDateFormatted"
						v-on="on"
						v-bind="attrs"
						prepend-inner-icon="far fa-calendar"
						readonly
						solo
						flat
						hide-details
						class="date-field"
					></v-text-field>
				</template>
				<v-date-picker v-model="currentDate" no-title @input="inputDatePicker()"></v-date-picker>
			</v-menu>
		</v-flex>
		<div>
			<v-btn text small @click.native="incrementDate">
				<v-icon>far fa-chevron-right</v-icon>
			</v-btn>
		</div>
		<v-flex xs12 md1 lg1 class="select-field-container" v-if="showZoomIn">
			<v-select
				v-model="zoomIn"
				:items="zoomItems"
				item-value="id"
				item-text="description"
				prepend-inner-icon="far fa-layer-group"
				:append-icon="null"
				solo
				flat
				hide-details
			></v-select>
		</v-flex>
		<v-flex xs12 md3 class="select-field-container">
			<v-select
				v-model="currentStatus"
				:items="statusItems"
				item-value="id"
				item-text="description"
				:prepend-inner-icon="currentStatusIcon"
				:append-icon="null"
				multiple
				solo
				flat
				hide-details
			></v-select>
		</v-flex>
		<v-flex xs12 md2 class="search-field-container">
			<v-text-field
				:placeholder="$t('operationstoolbar.search')"
				v-model="searchingText"
				prepend-inner-icon="far fa-search"
				solo
				flat
				outlined
				hide-details
			></v-text-field>
		</v-flex>
		<v-spacer></v-spacer>
		<v-flex xs12 md2 xl1 class="select-field-container">
			<v-select
				v-model="orderingBy"
				:items="orderingItems"
				item-value="id"
				item-text="description"
				prepend-inner-icon="far fa-sort-amount-down"
				:append-icon="null"
				solo
				flat
				hide-details
			></v-select>
		</v-flex>
		<operations-toolbar-info />
	</v-layout>
</template>

<script>
import OperationsToolbarInfo from '@/components/OperationsToolbarInfo.vue';

export default {
	name: 'OperationsToolbar',
	components: { OperationsToolbarInfo },
	props: {
		showZoomIn: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			now: new Date(),
			currentDate: null,
			menuDate: false,
			searchingText: '',
			zoomIn: 0,
			zoomItems: [
				{ id: 0, description: this.$t('operationstoolbar.zoom.1day') },
				{ id: 1, description: this.$t('operationstoolbar.zoom.3days') },
				{ id: 2, description: this.$t('operationstoolbar.zoom.5days') },
				{ id: 3, description: this.$t('operationstoolbar.zoom.7days') },
				{ id: 4, description: this.$t('operationstoolbar.zoom.9days') }
			],
			currentStatus: ['ESTIMATED', 'CONFIRMED', 'INITIATED', 'FINALIZED'],
			statusItems: [
				{ id: 'ESTIMATED', description: this.$t('operationstoolbar.status.estimated') },
				{ id: 'CONFIRMED', description: this.$t('operationstoolbar.status.confirmed') },
				{ id: 'INITIATED', description: this.$t('operationstoolbar.status.initiated') },
				{ id: 'FINALIZED', description: this.$t('operationstoolbar.status.finalized') }
			],
			orderingBy: 'eta',
			orderingItems: [
				{ id: 'eta', description: this.$t('operationstoolbar.sorting.eta.visit') },
				//{ id: 'eta_terminal', description: this.$t('operationstoolbar.eta.terminal') },
				//{ id: 'etd_terminal', description: this.$t('operationstoolbar.etd.terminal') },
				{ id: 'etd', description: this.$t('operationstoolbar.sorting.etd.visit') },
				{ id: 'etc_terminal', description: this.$t('operationstoolbar.sorting.etc.terminal') }
			],
			dialog: false
		};
	},
	computed: {
		computedDateFormatted() {
			return this.formatDate(this.currentDate);
		},
		currentStatusIcon() {
			if (!this.currentStatus || this.currentStatus.length === 0) {
				return 'far fa-battery-empty';
			} else if (this.currentStatus.length === 1) {
				return 'far fa-battery-quarter';
			} else if (this.currentStatus.length === 2) {
				return 'far fa-battery-half';
			} else if (this.currentStatus.length === 3) {
				return 'far fa-battery-three-quarters';
			} else {
				return 'far fa-battery-full';
			}
		}
	},
	watch: {
		currentDate() {
			this.$puiEvents.$emit('operations-toolbar_dateSelected', this.currentDate);
		},
		zoomIn() {
			this.$puiEvents.$emit('operations-toolbar_zoomIn', this.zoomIn);
		},
		currentStatus() {
			this.$puiEvents.$emit('operations-toolbar_statusSelected', this.currentStatus);
		},
		searchingText() {
			clearTimeout(this.delayTimer);
			this.delayTimer = setTimeout(() => {
				this.$puiEvents.$emit('operations-toolbar_searchingText', this.searchingText);
			}, 500);
		},
		orderingBy() {
			this.$puiEvents.$emit('operations-toolbar_orderingBy', this.orderingBy);
		}
	},
	created() {
		this.fillCurrentDate();
	},
	methods: {
		fillCurrentDate() {
			const dd = (this.now.getDate() < 10 ? '0' : '') + this.now.getDate();
			const MM = (this.now.getMonth() + 1 < 10 ? '0' : '') + (this.now.getMonth() + 1);
			const yyyy = this.now.getFullYear();
			this.currentDate = `${yyyy}-${MM}-${dd}`;
		},
		decrementDate() {
			this.now.setDate(this.now.getDate() - 1);
			this.fillCurrentDate();
		},
		incrementDate() {
			this.now.setDate(this.now.getDate() + 1);
			this.fillCurrentDate();
		},
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		inputDatePicker() {
			this.menuDate = false;
			this.now = new Date(this.currentDate);
		}
	}
};
</script>

<style lang="postcss">
.operations-toolbar {
	min-height: 65px;

	& .v-input__control {
		min-height: 40px !important;
	}
	& .select-field-container {
		margin-right: 24px;
	}
}
</style>
