<template>
	<v-dialog v-model="dialog" max-width="900">
		<template v-slot:activator="{ on: dialogActivator }">
			<v-tooltip top :z-index="200">
				<template v-slot:activator="{ on: tooltip }">
					<v-icon v-on="{ ...tooltip, ...dialogActivator }" small>fas fa-map-marked-alt</v-icon>
				</template>
				<span>{{ $t('operationsvisitmap.tooltip') }}</span>
			</v-tooltip>
		</template>

		<operations-visit-map :visit="visit" v-on:close="dialog = false" />
	</v-dialog>
</template>

<script>
import OperationsVisitMap from './OperationsVisitMap.vue';

export default {
	components: { OperationsVisitMap },
	props: {
		visit: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			dialog: false
		};
	}
};
</script>
