<template>
	<v-layout wrap>
		<v-navigation-drawer v-model="drawer" width="550" fixed temporary right>
			<operations-visit-detail v-if="chartDataItem && !chartDataItem.isService" :visit="chartDataItem"></operations-visit-detail>
			<operations-service-detail
				v-if="chartDataItem && chartDataItem.isService"
				:visit="chartDataItem.visit"
				:service="chartDataItem"
			></operations-service-detail>
		</v-navigation-drawer>
	</v-layout>
</template>

<script>
import OperationsVisitDetail from './detail/OperationsVisitDetail.vue';
import OperationsServiceDetail from './serviceDetail/OperationsServiceDetail.vue';

export default {
	components: { OperationsVisitDetail, OperationsServiceDetail },
	data() {
		return {
			drawer: false,
			chartDataItem: null
		};
	},
	watch: {
		drawer(newVal) {
			if (newVal) {
				this.$puiEvents.$emit('operations-navigationDrawer-show');
			} else {
				this.chartDataItem = null;
				this.$puiEvents.$emit('operations-navigationDrawer-hide');
			}
		}
	},
	created() {
		var self = this;
		this.$puiEvents.$on('operations-visitsDatatable-dataItemHit', (dataItem) => {
			console.log(dataItem);
			self.drawer = true;
			self.chartDataItem = dataItem;
		});
		this.$puiEvents.$on('operations-ganttChart-dataItemHit', (dataItem) => {
			self.drawer = true;
			self.chartDataItem = dataItem.dataContext;
		});
		this.$puiEvents.$on('operations-navigationDrawer-closed', () => {
			self.drawer = false;
			self.chartDataItem = null;
		});
	},
	beforeDestroy() {
		this.$puiEvents.$off('operations-visitsDatatable-dataItemHit');
		this.$puiEvents.$off('operations-ganttChart-dataItemHit');
		this.$puiEvents.$off('operations-navigationDrawer-closed');
	}
};
</script>
