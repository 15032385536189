<template>
	<v-card>
		<v-container>
			<v-row align="center">
				<v-col cols="11">
					<span class="operations-map__title">{{ visit.vesselimo + ' - ' + visit.vesselname }}</span>
				</v-col>
				<v-col cols="1" align="end">
					<v-btn icon ripple @click="close()">
						<v-icon>far fa-times</v-icon>
					</v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" v-if="stopPosition">
					<operations-visit-map-content :stopPosition="stopPosition" />
				</v-col>
				<v-col cols="12" v-else>
					<h4>{{ $t('operationsvisitmap.realtimelocationunknow') }}</h4>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>

<script>
import OperationsVisitMapContent from './OperationsVisitMapContent.vue';

/** Mixins */
import AISServiceMixin from '@/mixins/AISServiceMixin';

export default {
	components: { OperationsVisitMapContent },

	mixins: [AISServiceMixin],

	props: {
		visit: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			stopPosition: null
		};
	},

	created() {
		this.getAisPosition(this.visit);
	},

	methods: {
		async getAisPosition(stop) {
			this.stopPosition = await this.getAISVesselsPosition(stop);
		},
		close() {
			this.$emit('close');
		}
	}
};
</script>
