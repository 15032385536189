<template>
	<div class="operations-legend">
		<v-layout justify-center>
			<operations-legend-block
				:title="$t('operationslegend.arrival')"
				:estimatedColor="arrivalEstimatedColor"
				:realColor="arrivalRealColor"
			></operations-legend-block>
			<operations-legend-block
				:title="$t('operationslegend.cargo')"
				:estimatedColor="cargoEstimatedColor"
				:realColor="cargoRealColor"
			></operations-legend-block>
			<operations-legend-block
				:title="$t('operationslegend.anchored')"
				:estimatedColor="anchoredEstimatedColor"
				:realColor="anchoredRealColor"
			></operations-legend-block>
			<operations-legend-block
				:title="$t('operationslegend.departure')"
				:estimatedColor="departureEstimatedColor"
				:realColor="departureRealColor"
			></operations-legend-block>
		</v-layout>
	</div>
</template>

<script>
import OperationsLegendBlock from './OperationsLegendBlock';

export default {
	components: {
		OperationsLegendBlock
	},
	data() {
		return {
			arrivalEstimatedColor: '#F1BCF2',
			arrivalRealColor: '#B758D9',
			cargoEstimatedColor: '#BADD8E',
			cargoRealColor: '#4FA439',
			anchoredEstimatedColor: '#AFDEF8',
			anchoredRealColor: '#1F98EF',
			departureEstimatedColor: '#FDDEB6',
			departureRealColor: '#EF7217'
		};
	}
};
</script>
